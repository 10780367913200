import React, { useState, useEffect } from 'react';
import { BsArrowRightCircleFill, BsArrowLeftCircleFill } from 'react-icons/bs';
import image1 from '../data/image1.jpeg';
import image2 from '../data/image2.jpeg';
import image3 from '../data/image3.jpeg';
import image4 from '../data/image4.jpeg';
import img1 from '../data/img1.jpg'
import img2 from '../data/img2.jpg'
import img3 from '../data/img3.jpg'
import img4 from '../data/img4.jpg'

const ImgSlider = () => {
  const images = [image1, image2,image3,image4];
  const images2 = [img1,img2,img3,img4];
  const [currentImg, setCurrentImg] = useState(0);

  const handlePrevious = () => {
    setCurrentImg(currentImg === 0 ? images.length - 1 : currentImg - 1);
  };

  const handleNext = () => {
    setCurrentImg(currentImg === images.length - 1 ? 0 : currentImg + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImg((prevIndex) => (prevIndex + 1) % images.length);
    }, 12000);
    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  return (
    <div className="relative flex flex-col justify-center items-center w-full h-[570px] sm:h-[450px] xl:h-[550px] xl:my-8 gap-4">
      <BsArrowLeftCircleFill
        className="arrow absolute w-6 h-6 sm:w-8 sm:h-8 text-gray-700 filter drop-shadow-lg cursor-pointer left-4 z-10"
        onClick={handlePrevious}
      />
      <div className="hidden sm:block w-[90%] h-full xl:h-[500px] overflow-hidden relative">
        {images && images.length
          ? images.map((imageurl, index) => (
              <img
                key={index}
                src={imageurl}
                alt={`img${index}`}
                className={`rounded-md shadow-md w-full h-full absolute transition-opacity duration-1000 ease-in-out ${
                  currentImg === index ? 'opacity-100' : 'opacity-0'
                }`}
                style={{ top: 0, left: 0 }}
              />
            ))
          : null}
      </div>
      <div className="sm:hidden w-full h-full overflow-hidden relative">
        {images2 && images2.length
          ? images2.map((imageurl, index) => (
              <img
                key={index}
                src={imageurl}
                alt={`img${index}`}
                className={`rounded-md shadow-md w-full h-full absolute transition-opacity duration-1000 ease-in-out ${
                  currentImg === index ? 'opacity-100' : 'opacity-0'
                }`}
                style={{ top: 0, left: 0 }}
              />
            ))
          : null}
      </div>
      <BsArrowRightCircleFill
        className="arrow absolute w-6 sm:w-8 h-6 sm:h-8 text-gray-700 filter drop-shadow-lg cursor-pointer right-4 z-10"
        onClick={handleNext}
      />

      <span className="relative flex z-10">
        {images && images.length
          ? images.map((_, index) => (
              <button
                key={index}
                className={`h-1 w-10 rounded-full border-none outline-none mx-1 cursor-pointer ${
                  currentImg === index ? 'bg-white' : 'bg-gray-500'
                }`}
                onClick={() => setCurrentImg(index)}
              ></button>
            ))
          : null}
      </span>
    </div>
  );
};

export default ImgSlider;
